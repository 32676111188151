"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GmxProvider = void 0;
const bignumber_1 = require("@ethersproject/bignumber");
const chain_1 = require("@zenlink-interface/chain");
const currency_1 = require("@zenlink-interface/currency");
const abis_1 = require("../abis");
const entities_1 = require("../entities");
const util_1 = require("../util");
const LiquidityProvider_1 = require("./LiquidityProvider");
const BRIDGED_USDC = new currency_1.Token({
    chainId: chain_1.ParachainId.ARBITRUM_ONE,
    address: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    decimals: 6,
    name: 'USD Coin (Arb1)',
    symbol: 'USDC.e',
});
const GMX_SWAP_FEE = 0.003;
const GMX_STABLE_SWAP_FEE = 0.0004;
const GMX_TAX_FEE = 0.005;
const GMX_STABLE_TAX_FEE = 0.002;
class GmxProvider extends LiquidityProvider_1.LiquidityProvider {
    constructor(chainId, client) {
        super(chainId, client);
        this.swapFee = GMX_SWAP_FEE;
        this.stableSwapFee = GMX_STABLE_SWAP_FEE;
        this.poolCodes = [];
        this.initialPools = new Map();
        this.vault = {
            [chain_1.ParachainId.ARBITRUM_ONE]: '0x489ee077994B6658eAfA855C308275EAd8097C4A',
        };
        this.tokens = {
            [chain_1.ParachainId.ARBITRUM_ONE]: [
                currency_1.WETH9[chain_1.ParachainId.ARBITRUM_ONE],
                currency_1.WBTC[chain_1.ParachainId.ARBITRUM_ONE],
                currency_1.USDC[chain_1.ParachainId.ARBITRUM_ONE],
                currency_1.USDT[chain_1.ParachainId.ARBITRUM_ONE],
                currency_1.DAI[chain_1.ParachainId.ARBITRUM_ONE],
                currency_1.FRAX[chain_1.ParachainId.ARBITRUM_ONE],
                currency_1.UNI[chain_1.ParachainId.ARBITRUM_ONE],
                currency_1.LINK[chain_1.ParachainId.ARBITRUM_ONE],
                BRIDGED_USDC,
            ],
        };
        this.stableTokens = {
            [chain_1.ParachainId.ARBITRUM_ONE]: {
                [currency_1.USDC_ADDRESS[chain_1.ParachainId.ARBITRUM_ONE]]: true,
                [currency_1.USDT_ADDRESS[chain_1.ParachainId.ARBITRUM_ONE]]: true,
                [currency_1.DAI_ADDRESS[chain_1.ParachainId.ARBITRUM_ONE]]: true,
                [currency_1.FRAX_ADDRESS[chain_1.ParachainId.ARBITRUM_ONE]]: true,
                [BRIDGED_USDC.address]: true,
            },
        };
    }
    async _fetchPools(tokens) {
        const tokenMaxPriceCalls = this.client
            .multicall({
            allowFailure: true,
            contracts: tokens.map(token => ({
                args: [token.address],
                address: this.vault[this.chainId],
                chainId: chain_1.chainsParachainIdToChainId[this.chainId],
                abi: abis_1.gmxVault,
                functionName: 'getMaxPrice',
            })),
        })
            .catch((e) => {
            console.warn(`${e.message}`);
            return undefined;
        });
        const tokenMinPriceCalls = this.client
            .multicall({
            allowFailure: true,
            contracts: tokens.map(token => ({
                args: [token.address],
                address: this.vault[this.chainId],
                chainId: chain_1.chainsParachainIdToChainId[this.chainId],
                abi: abis_1.gmxVault,
                functionName: 'getMinPrice',
            })),
        })
            .catch((e) => {
            console.warn(`${e.message}`);
            return undefined;
        });
        const poolAmountsCalls = this.client
            .multicall({
            allowFailure: true,
            contracts: tokens.map(token => ({
                args: [token.address],
                address: this.vault[this.chainId],
                chainId: chain_1.chainsParachainIdToChainId[this.chainId],
                abi: abis_1.gmxVault,
                functionName: 'poolAmounts',
            })),
        })
            .catch((e) => {
            console.warn(`${e.message}`);
            return undefined;
        });
        const reservedAmountsCalls = this.client
            .multicall({
            allowFailure: true,
            contracts: tokens.map(token => ({
                args: [token.address],
                address: this.vault[this.chainId],
                chainId: chain_1.chainsParachainIdToChainId[this.chainId],
                abi: abis_1.gmxVault,
                functionName: 'reservedAmounts',
            })),
        })
            .catch((e) => {
            console.warn(`${e.message}`);
            return undefined;
        });
        const usdgAmountsCalls = this.client
            .multicall({
            allowFailure: true,
            contracts: tokens.map(token => ({
                args: [token.address],
                address: this.vault[this.chainId],
                chainId: chain_1.chainsParachainIdToChainId[this.chainId],
                abi: abis_1.gmxVault,
                functionName: 'usdgAmounts',
            })),
        })
            .catch((e) => {
            console.warn(`${e.message}`);
            return undefined;
        });
        const maxUsdgAmountsCalls = this.client
            .multicall({
            allowFailure: true,
            contracts: tokens.map(token => ({
                args: [token.address],
                address: this.vault[this.chainId],
                chainId: chain_1.chainsParachainIdToChainId[this.chainId],
                abi: abis_1.gmxVault,
                functionName: 'maxUsdgAmounts',
            })),
        })
            .catch((e) => {
            console.warn(`${e.message}`);
            return undefined;
        });
        return await Promise.all([
            tokenMaxPriceCalls,
            tokenMinPriceCalls,
            poolAmountsCalls,
            reservedAmountsCalls,
            usdgAmountsCalls,
            maxUsdgAmountsCalls,
        ]);
    }
    async getPools(tokens) {
        if (!(this.chainId in this.vault)
            || !(this.chainId in this.tokens)
            || !(this.chainId in this.stableTokens)) {
            this.lastUpdateBlock = -1;
            return;
        }
        // tokens deduplication
        const tokenMap = new Map();
        tokens.forEach(t => tokenMap.set((0, util_1.formatAddress)(t.address), t));
        const tokensDedup = Array.from(tokenMap.values());
        // tokens sorting
        const tok0 = tokensDedup.map(t => [(0, util_1.formatAddress)(t.address), t]);
        tokens = tok0.sort((a, b) => (b[0] > a[0] ? -1 : 1)).map(([_, t]) => t);
        const [maxPrices, minPrices, poolAmounts, reservedAmounts, usdgAmounts, maxUsdgAmounts,] = await this._fetchPools(tokens);
        for (let i = 0; i < tokens.length; i++) {
            for (let j = i + 1; j < tokens.length; j++) {
                const t0 = tokens[i];
                const t1 = tokens[j];
                const poolAmount0 = poolAmounts?.[i].result;
                const poolAmount1 = poolAmounts?.[j].result;
                const reservedAmount0 = reservedAmounts?.[i].result;
                const reservedAmount1 = reservedAmounts?.[j].result;
                const token0MaxPrice = maxPrices?.[i].result;
                const token0MinPrice = minPrices?.[i].result;
                const token1MaxPrice = maxPrices?.[j].result;
                const token1MinPrice = minPrices?.[j].result;
                const usdgAmount0 = usdgAmounts?.[i].result;
                const usdgAmount1 = usdgAmounts?.[j].result;
                const maxUsdgAmount0 = maxUsdgAmounts?.[i].result;
                const maxUsdgAmount1 = maxUsdgAmounts?.[j].result;
                if (maxPrices?.[i].status !== 'success' || !token0MaxPrice
                    || maxPrices?.[j].status !== 'success' || !token1MaxPrice
                    || minPrices?.[i].status !== 'success' || !token0MinPrice
                    || minPrices?.[j].status !== 'success' || !token1MinPrice
                    || !poolAmount0 || !poolAmount1
                    || !reservedAmount0 || !reservedAmount1
                    || !maxUsdgAmount0 || !maxUsdgAmount1
                    || !usdgAmount0 || !usdgAmount1) {
                    continue;
                }
                const stableTokens = this.stableTokens[this.chainId];
                const isStablePool = stableTokens[t0.address] && stableTokens[t1.address];
                const pool = new entities_1.GmxPool(this.vault[this.chainId], t0, t1, isStablePool ? this.stableSwapFee : this.swapFee, bignumber_1.BigNumber.from(poolAmount0 - reservedAmount0), bignumber_1.BigNumber.from(poolAmount1 - reservedAmount1), bignumber_1.BigNumber.from(usdgAmount0), bignumber_1.BigNumber.from(usdgAmount1), bignumber_1.BigNumber.from(maxUsdgAmount0), bignumber_1.BigNumber.from(maxUsdgAmount1), bignumber_1.BigNumber.from(token0MaxPrice), bignumber_1.BigNumber.from(token0MinPrice), bignumber_1.BigNumber.from(token1MaxPrice), bignumber_1.BigNumber.from(token1MinPrice), GMX_SWAP_FEE, GMX_STABLE_SWAP_FEE, GMX_TAX_FEE, GMX_STABLE_TAX_FEE);
                const pc = new entities_1.GmxPoolCode(pool, this.getPoolProviderName());
                this.initialPools.set(`${t0.address}_${t1.address}`, pool);
                this.poolCodes.push(pc);
                ++this.stateId;
            }
        }
    }
    startFetchPoolsData() {
        this.stopFetchPoolsData();
        this.poolCodes = [];
        this.getPools(this.tokens[this.chainId] || []); // starting the process
        this.unwatchBlockNumber = this.client.watchBlockNumber({
            onBlockNumber: (blockNumber) => {
                this.lastUpdateBlock = Number(blockNumber);
            },
            onError: (error) => {
                console.error(error.message);
            },
        });
    }
    async fetchPoolsForToken(_t0, _t1) {
        await this.getPools(this.tokens[this.chainId] || []);
    }
    getCurrentPoolList() {
        return this.poolCodes;
    }
    stopFetchPoolsData() {
        if (this.unwatchBlockNumber)
            this.unwatchBlockNumber();
    }
    getType() {
        return LiquidityProvider_1.LiquidityProviders.GMX;
    }
    getPoolProviderName() {
        return 'GMX';
    }
}
exports.GmxProvider = GmxProvider;
